import {useEffect} from 'react';
import Slides from '@assets/images/logo-full.svg';
export default function MainPage() {
    useEffect(() => {
        document.title = 'Слайды — сервис для создания интерактивных презентаций';

    }, []);
return (
<section className="container-index">
    <img className="index-logo" src={Slides} alt="Слайды — сервис для создания интерактивных презентаций" />
    <h1>Слайды — сервис для создания интерактивных презентаций</h1>
    <p>Сервис будет полезен как для организаторов мероприятий, так и для спикеров, которые хотят повысить степень
        вовлеченности аудитории</p>
    <div class="index-btn">
        <a className="btn btn-green" href="/login">Вход в личный кабинет</a>
        <a className="btn btn-primary" href="https://t.me/slides_channel" target='_blank'>Телеграм-канал </a>
    </div>
    <p>Презентация: <a href="https://disk.yandex.ru/d/tgpdm-EAtIEhDQ" target='_blank'>коммерческое предложение</a></p>
    <p>‌Максим Ермаков, <br />
        ‌генеральный директор ООО «Слайды»<br />
        ‌8 995 781 47 87 <br />
        ‌maxim.ermakov.ru@yandex.ru </p>
<footer>
    <p><a href="https://disk.yandex.ru/d/j1-aFJM2xFNNww" target='_blank'>Политика обработки персональных данных (ООО
            «Слайды») </a></p>
    <p><a href="https://disk.yandex.ru/d/kaCjARa3N7GDGw" target='_blank'>Пользовательское соглашение (ООО «Слайды») </a>
    </p>
    <p>Стартап реализуется при поддержке Университетской стартап-студии «2F», ООО «Астраханский технопарк СК», команды
        разработчиков Сорокина В. В.</p>
    <p>‌2024 год</p>
</footer>
</section>
);
}