import { useEffect, useState } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import { loadAuthUser } from '@utils/auth';
import { loadUserPresentations, deletePresentation, createNewPresentation, copyPresentation, renamePresentation } from '@utils/presentation';
import { Link, useNavigate } from 'react-router-dom';

import LogoutButton from '@profile/logout-button';

export default function ProfilePage() {
    const [user, setUser] = useState(null);
    const [presentations, setPresentations] = useState([]);
    const [selected_uuid, setSelectedUuid] = useState(null);
    const [new_name, setNewName] = useState('');
    const [show_delete, setShowDelete] = useState(false);
    const [show_create, setShowCreate] = useState(false);
    const [show_copy, setShowCopy] = useState(false);
    const [show_rename, setShowRename] = useState(false);

    const [name_valid, setValidName] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Личный кабинет';

        let dataFetch = async () => {
            let auth_user = await loadAuthUser();

            if (auth_user == null)
                navigate('/error', { state: { code: 403, msg: 'У вас нет прав для просмотра данной страницы' } })
            else
                setUser(auth_user);

            let loaded_presentations = await loadUserPresentations();
            setPresentations(loaded_presentations);
        };

        dataFetch().then(r => { });
    }, []);

    let deleteHandle = (uuid) => {
        setSelectedUuid(uuid);
        setShowDelete(true);
    }

    let renameHandle = (uuid) => {
        setSelectedUuid(uuid);
        setNewName(presentations.find(p => p.uuid === uuid)?.name ?? '');
        setShowRename(true);
    }

    let copyHandle = (uuid) => {
        setSelectedUuid(uuid);
        setNewName(presentations.find(p => p.uuid === uuid)?.name ?? '');
        setShowCopy(true);
    }

    let createHandle = () => {
        setShowCreate(true);
    }

    let createPresentationAction = async () => {
        if (new_name <= 2) {
            setValidName(false);
            return;
        }

        let presentation = await createNewPresentation(new_name);

        if (presentation)
            setPresentations([...presentations, presentation]);

        setNewName('');
        setValidName(true);
        setShowCreate(false);

        window.open(`/editor/${presentation.uuid}`, '_blank', 'noreferrer');
    }

    let deletePresentationAction = async () => {
        let status = await deletePresentation(selected_uuid);

        if (status) {
            setPresentations(presentations.filter(p => p.uuid !== selected_uuid));
            setSelectedUuid(null);
        }

        setShowDelete(false);
    }

    let copyPresentationAction = async () => {
        if (new_name <= 2) {
            setValidName(false);
            return;
        }

        let presentation = await copyPresentation(new_name, selected_uuid);

        if (presentation)
            setPresentations([...presentations, presentation]);

        setNewName('');
        setValidName(true);
        setShowCopy(false);
    }

    let renamePresentationAction = async () => {
        if (new_name <= 2) {
            setValidName(false);
            return;
        }

        await renamePresentation(new_name, selected_uuid);

        setPresentations([...presentations.map(p => (p.uuid === selected_uuid ? Object.assign(p, { name: new_name }) : p))]);

        setNewName('');
        setValidName(true);
        setShowRename(false);
    }

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="user-info">
                            <div>
                                {
                                    user != null ?
                                        <>
                                            <b>{user.fname} {user.lname}</b><br />
                                            <i>{user.email}</i>
                                        </>
                                        :
                                        ''
                                }
                            </div>
                            <LogoutButton></LogoutButton>
                        </div>
                        <h4 className='mt-4'>Список ваших презентаций:</h4>
                        <ul className='pres-list'>
                            {(presentations || []).map((p, i) =>
                                <li key={i}>
                                    <Link target={'_blank'} to={`/editor/${p.uuid}`}>{p.name}</Link>
                                    <div className='edit-buttons'>
                                        <button title='Переименовать презентацию' onClick={() => renameHandle(p.uuid)} className='rename-icon'></button>
                                        <button title='Копировать презентацию' onClick={() => copyHandle(p.uuid)} className='copyy-icon'></button>
                                        <button title='Удалить презентацию' onClick={() => deleteHandle(p.uuid)} className='delete-icon'></button>
                                    </div>
                                </li>
                            )}
                        </ul>
                        <Button onClick={createHandle}>Создать новую презентацию</Button>
                    </div>
                </div>
            </div>

            <Modal show={show_delete} onHide={() => setShowDelete(false)} centered>
                <Modal.Body>Вы уверены, что хотите удалить презентацию?</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={deletePresentationAction}>
                        Удалить
                    </Button>
                    <Button variant="primary" onClick={() => setShowDelete(false)}>
                        Отмена
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show_create} onHide={() => setShowCreate(false)} centered>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>Новая презентация</Form.Label>
                        <input type="text" className="form-control" value={new_name} onChange={(e) => setNewName(e.target.value)}></input>
                        {name_valid ? '' : <span className="color-red">Минимальная длина для имени 2 символа</span>}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={createPresentationAction}>
                        Создать
                    </Button>
                    <Button variant="primary" onClick={() => setShowCreate(false)}>
                        Отмена
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show_copy} onHide={() => setShowCopy(false)} centered>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>Копия презентации</Form.Label>
                        <input type="text" className="form-control" value={new_name} onChange={(e) => setNewName(e.target.value)}></input>
                        {name_valid ? '' : <span className="color-red">Минимальная длина для имени 2 символа</span>}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={copyPresentationAction}>
                        Создать
                    </Button>
                    <Button variant="primary" onClick={() => setShowCopy(false)}>
                        Отмена
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show_rename} onHide={() => setShowRename(false)} centered>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>Переименовать</Form.Label>
                        <input type="text" className="form-control" value={new_name} onChange={(e) => setNewName(e.target.value)}></input>
                        {name_valid ? '' : <span className="color-red">Минимальная длина для имени 2 символа</span>}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={renamePresentationAction}>
                        Сохранить
                    </Button>
                    <Button variant="primary" onClick={() => setShowRename(false)}>
                        Отмена
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}